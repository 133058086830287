import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const ricerepublicCustomerGroup = 'rice_republic';
export const customerGroup = ricerepublicCustomerGroup;

const supportEmail = {
	rice_republic: 'ricerepublic@smoothr.de',
};
const firebaseConfig = {
	rice_republic: {
		apiKey: "AIzaSyCgCa9i8LB39JSEeiW2WTEgY_WSTCo3ros",
		authDomain: "ricerepublic-web-app.firebaseapp.com",
		projectId: "ricerepublic-web-app",
		storageBucket: "ricerepublic-web-app.appspot.com",
		messagingSenderId: "1046664011470",
		appId: "1:1046664011470:web:86725b4da57f80d080ba9f"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAvwSXOAOJaLHcTw98kusEIo-LMTtym00Y',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
};
