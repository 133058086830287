import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const ricerepublicCustomerGroup = 'rice_republic';
export const customerGroup = ricerepublicCustomerGroup;

const supportEmail = {
	rice_republic: 'ricerepublic@smoothr.de',
};
const firebaseConfig = {
	rice_republic: {
		apiKey: "AIzaSyCgCa9i8LB39JSEeiW2WTEgY_WSTCo3ros",
		authDomain: "ricerepublic-web-app.firebaseapp.com",
		projectId: "ricerepublic-web-app",
		storageBucket: "ricerepublic-web-app.appspot.com",
		messagingSenderId: "1046664011470",
		appId: "1:1046664011470:web:35b494c8e73c8e8280ba9f"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBskl-C8eeJ1v3KVwjdr-XljiQv5dM8JQM',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
};
