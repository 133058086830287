import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {TranslateService} from '@ngx-translate/core';
import Localization from 'src/smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	private _order: Order;
	priceText = '';
	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadPrice();
	}

	numberToCurrency = numberToCurrency;

	constructor(private translate: TranslateService) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}
	@Input()
	categoryName: Localization;

	private reloadPrice() {
		if (this.article && this._order?.preorder?.type) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(
				this.article,
				[],
				this.article.defaults,
				this._order.preorder.type
			);
			articleGroup.quantity = 1;
			const groups = this.test(articleGroup);
			if (
				groups.length > 0 &&
				this.article?.tags?.length > 0 &&
				this.article.tags?.filter(it => it.identifier === 'fromPrice').length >
					0
			) {
				const groupsPrice = groups
					.filter(it => it.requiredAmount > 0)
					.map(it => {
						return Math.min(
							...it.articles.map(
								article => numberD(article.price) * it.requiredAmount
							)
						);
					});
				this.price = groupsPrice.reduce((a, b) => a + b, 0);
				this.priceText = numberToCurrency(this.price, this.currency);
				return;
			} else {
				this.price = OrderUtils.totalPrice(
					articleGroup,
					this.order?.type,
					this.order?.preorder?.type
				);
			}
			this.priceText = numberToCurrency(this.price, this.currency);
		}
	}

	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		const indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (
				page.length > 0 &&
				articleGroup.article.recommendations.find(
					recommendation => recommendation.group === page[0]._id
				)
			) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	isTagPriceFrom() {
		return this.article &&
			this.article?.tags?.length > 0 &&
			this.article?.tags?.find(tag => tag?.identifier === 'fromPrice')
			? this.translate.instant('item_view.price_from')
			: '';
	}
	getArticleTags() {
		return Object.entries(this.article?.compound?.features || {})
			.filter(value => value[1] === true)
			.map(it => it[0])
			.filter(it => it === 'vegan' || it === 'vegetarian');
	}
	errorImage(image) {
		if (this.article.assets.original)
			image.src = this.article?.assets?.original;
		else '';
	}
	public showMeasurment() {
		return this.article?.measurement?.amount &&
			this.article?.measurement?.refUnit
			? `${this.article?.measurement?.amount} ${this.currency}/${this.article?.measurement?.refUnit}`
			: '';
	}
}
