import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import Localization from 'src/smoothr-web-app-core/models/Localization';
import {Observable} from 'rxjs';

@Pipe({name: 'langObservableCustom'})
export class LangObservableCustomPipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: Localization): Observable<string> {
		return this.translate.onLangChange.pipe(
			startWith(''),
			map(() => {
				if (!value) {
					return '';
				}
				if (value[this.translate.currentLang]) {
					return value[this.translate.currentLang];
				} else {
					return value.fr;
				}
			})
		);
	}
}